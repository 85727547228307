$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.gallery-main-container {
  width: 80%;
  margin: 7rem auto;
  .gallery-heading-container {
    padding: 3rem 0;
    h1 {
      font-family: $primaryFront;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid $ternaryColor;
      width: fit-content;
    }
    p {
      padding-top: 1rem;
      font-family: $secondaryFront;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 2rem;
    }
  }
}

@media (max-width: 1100px) {
  .gallery-main-container {
    .gallery-heading-container {
      h1 {
        font-size: 42px;
      }
    }
  }
}

@media (max-width: 720px) {
    .gallery-main-container {
      margin: 4rem auto;
      margin-bottom: 6rem;
        .gallery-heading-container {
          h1 {
            font-size: 31px;
          }
          p{
            font-size: 15px;
          }
        }
      }
}

@media (max-width: 500px) {
    .gallery-main-container {
      margin: 2rem auto ;
        .gallery-heading-container {
          h1 {
            font-size: 25px;
          }
          p{
            font-size: 13px;
          }
        }
      }
}
@media (max-width: 420px) {
    .gallery-main-container {
        .gallery-heading-container {
          h1 {
            font-size: 20px;
          }
          p{
            font-size: 10px;
          }
        }
      }
}

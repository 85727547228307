$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;




.no-product-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5rem;
  .no-product{
    font-size: 3rem;
    font-family: $secondaryFront;
  }
}

.all-product-container {
  // padding: 2rem 10rem;
  width: 85%;
  margin: 2rem auto;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .heading-container {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .heading {
      font-family: $primaryFront;
      font-size: 38px;
      font-weight: 500;
      line-height: 51px;
      letter-spacing: 0.005em;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .catagories {
      margin: 1rem 0;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      overflow-x: scroll;
      .flex {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }
      li {
        list-style: none;
        font-family: $ternaryFront;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.005em;
        text-align: start;
        padding: 1rem;
        color: $secondaryColor;
        
        white-space: nowrap;
        padding: 1rem 1.5rem;
        border-radius: 3rem;
        cursor: pointer;
        text-transform: capitalize;
      }
      .active{
        color: $primaryColor;
      }
      li:hover{
        background-color: $primaryColor;
        color:$whiteColor ;
      }
      
    }
    .catagories::-webkit-scrollbar{
      display: none;
    }
  }
  .product-container {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-items: center;
    grid-gap: 2rem;
  }
  a {
    text-decoration: none;
  }
}

@media (max-width: 1380px) {
  .all-product-container {
    margin-top: 7rem;
    .product-container {
      grid-template-columns: repeat(3, auto);
    }
  }
}
@media (max-width: 1064px) {
  .all-product-container {
    .product-container {
      grid-template-columns: repeat(2, auto);
    }
  }
  .no-product-container{
    .no-product{
      font-size: 2rem;
    }
  }
}
@media (max-width: 720px) {

  .no-product-container{
    .no-product{
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 613px) {
  .all-product-container {
    margin-top: 5rem;
    .heading-container {
      margin: 0 auto;
      .heading {
        font-size: 30px;
        margin-bottom: 0;
      }
      .catagories {
        li {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}
@media (max-width: 460px) {
  .all-product-container {
    margin-top: 4rem;

    .heading-container {
      width: 98%;
      .heading {
        font-size: 25px;
      }
      .catagories {
        overflow-x: scroll;
      }
    }
  }
  .no-product-container{
    .no-product{
      font-size: 1.3rem;
    }
  }
}
@media (max-width: 437px) {
  .all-product-container {
    margin-top: 3rem;
  }
}
@media (max-width: 360px) {
  .no-product-container{
    .no-product{
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 460px) {
  .all-product-container {
    .product-container {
      grid-template-columns: repeat(1, auto);
    }
  }
}

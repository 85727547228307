$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.slider {
  height: 100%;
  width: 100%;
  .bg-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100vw;
    }
    .Mirzazadeh {
      position: absolute;
      font-family: $secondaryFront;
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 0.3em;
      text-align: left;
      rotate: 90deg;
      color: $whiteColor;
      top: 53%;
      left: -7%;
      span {
        font-weight: 200;
        font-family: $secondaryFront;
        opacity: 0.4;
      }
    }
    .learn--container {
      position: absolute;
      bottom: 10%;
      right: 10%;
      color: $whiteColor;
      cursor: pointer;
      a {
        color: $whiteColor;
        .learn-more {
          font-family: $secondaryFront;
          font-size: 1.6rem;
          font-weight: 400;
          letter-spacing: 0.3em;
          .arrow {
            margin-bottom: -5px;
          }
        }
        .dash {
          width: 13rem;
          margin-left: -2rem;
          border-bottom: 2px solid $whiteColor;
          margin-top: 1rem;
        }
      }
    }
    .learn--container:hover {
      bottom: 11%;
    }
    .title {
      position: absolute;
      top: 45%;
      left: 21%;
      .heading {
        font-family: $primaryFront;
        font-size: 4rem;
        font-weight: 500;
        line-height: 8.7rem;
        letter-spacing: 0em;
        text-align: left;
        color: $whiteColor;
        text-transform: uppercase;
      }
      .pra {
        font-family: $secondaryFront;
        font-size: 2.4rem;
        font-weight: 300;
        line-height: 2.6rem;
        letter-spacing: 0.1em;
        text-align: left;
        color: $whiteColor;
        text-transform: uppercase;
        margin-top: -10px;
      }
    }
  }
  .bg-hidden {
    display: none;
  }
}

@media (max-width: 1209px) {
  .slider {
    .bg-img {
      .title {
        top: 40%;
        left: 18%;
        .heading {
          font-size: 3.5rem;
        }
        .pra {
          font-size: 1.8rem;
        }
      }
    }
  }
}
@media (max-width: 1057px) {
  .slider {
    .bg-img {
      .Mirzazadeh {
        font-size: 11px;
        top: 53%;
        left: -10%;
        span {
          font-weight: 200;
          font-family: "Poppins";
        }
      }

      .learn--container {
        bottom: 5%;
        right: 5%;
        a {
          .learn-more {
            font-size: 1rem;
            .arrow {
              margin-bottom: -5px;
            }
          }
          .dash {
            width: 10rem;
          }
        }
      }

      .title {
        top: 40%;
        left: 18%;
        .heading {
          font-size: 3.5rem;
        }
        .pra {
          font-size: 1.8rem;
          margin-top: -2rem;
        }
      }
    }
  }
}
@media (max-width: 875px) {
  .slider {
    .bg-img {
      .Mirzazadeh {
        font-size: 8px;
        top: 53%;
        left: -8%;
        span {
          font-weight: 200;
          font-family: "Poppins";
        }
      }

      .learn--container {
        bottom: 5%;
        right: 5%;
        a {
          .learn-more {
            font-size: 0.8rem;
            .arrow {
              margin-bottom: -5px;
            }
          }
          .dash {
            width: 8rem;
          }
        }
      }
      .title {
        top: 35%;
        left: 15%;
        .heading {
          font-size: 2.7rem;
        }
        .pra {
          font-size: 1.2rem;
        }
      }
    }
  }
}
@media (max-width: 663px) {
  .slider {
    .bg-img {
      .Mirzazadeh {
        font-size: 8px;
        top: 53%;
        left: -8%;
        span {
          font-weight: 200;
        }
      }

      .learn--container {
        bottom: 5%;
        right: 5%;
        a {
          .learn-more {
            font-size: 0.8rem;
            .arrow {
              margin-bottom: -5px;
            }
          }
          .dash {
            width: 8rem;
          }
        }
      }

      .title {
        top: 35%;
        left: 15%;
        .heading {
          font-size: 2.1rem;
        }
        .pra {
          font-size: 0.8rem;
          margin-top: -3rem;
        }
      }
    }
  }
}
@media (max-width: 563px) {
  .slider {
    .bg-img {
      .Mirzazadeh {
        font-size: 8px;
        top: 53%;
        left: -15%;
        span {
          font-weight: 200;
        }
      }
      
        .learn--container {
          bottom: 5%;
          right: 5%;
          a{
            .learn-more {
              font-size: 0.5rem;
            }
            .dash {
              width: 5rem;
              margin-top: 0;
            }
          }
        }
      
      .title {
        top: 35%;
        left: 10%;
        .heading {
          font-size: 1.9rem;
        }
        .pra {
          font-size: 0.8rem;
          margin-top: -3.5rem;
        }
      }
    }
  }
}
@media (max-width: 470px) {
  .slider {
    .bg-img {
      .Mirzazadeh {
        font-size: 5px;
        top: 40%;
        left: -9%;
        span {
          font-weight: 200;
        }
      }
      
        
      
      .title {
        top: 35%;
        left: 10%;
        .heading {
          font-size: 1.7rem;
        }
        .pra {
          font-size: 0.8rem;
          margin-top: -3.5rem;
        }
      }
    }
  }
}
@media (max-width: 432px) {
  .slider {
    .bg-img {
      .Mirzazadeh {
        font-size: 5px;
        top: 40%;
        left: -12%;
        span {
          font-weight: 200;
        }
      }
      .title {
        top: 28%;
        left: 10%;
        .heading {
          font-size: 1.6rem;
        }
        .pra {
          font-size: 0.8rem;
          margin-top: -4rem;
        }
      }
    }
  }
}
@media (max-width: 382px) {
  .slider {
    .bg-img {
      .title {
        top: 20%;
        left: 10%;
        .heading {
          font-size: 1.4rem;
        }
        .pra {
          font-size: 0.7rem;
          margin-top: -3.8rem;
        }
      }
    }
  }
}
@media (max-width: 342px) {
  .slider {
    .bg-img {
      .title {
        top: 20%;
        left: 10%;
        .heading {
          font-size: 1rem;
        }
        .pra {
          font-size: 0.5rem;
          margin-top: -3.8rem;
        }
      }
    }
  }
}

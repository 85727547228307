$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.cart-item-clear {
  margin: 2rem auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 0.5rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eee;
  .cart-item-number {
    text-decoration: underline;
    margin-top: 0.8rem;
    font-family: $secondaryFront;
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: 0.005em;
    text-align: center;
  }
  .cart-item-number {
    text-decoration: underline;
    margin-top: 0.8rem;
    font-family: $secondaryFront;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.005em;
    text-align: center;
  }
  .clear-cart-btn{
    color: $primaryColor;
    cursor: pointer;
  }
}

.cart-container {
  width: 80%;
  margin: 3rem auto;
  // background-color: $ternaryColor;
  min-height: 50rem;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  .heading {
    font-size: 5rem;
    text-align: center;
    font-family: $primaryFront;
    font-weight: 500;
    letter-spacing: 0em;
    padding: 1rem;
    border-bottom: 1px solid black;
    width: fit-content;
    margin: 0 auto;
  }
  .empty {
    margin-top: 10rem;
  }
  .empty-heading {
    margin: -1.5rem;
    margin-top: 5rem;
    font-family: $secondaryFront;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 0.005em;
    text-align: center;
  }


  .go-home {
    padding: 1rem 5rem;
    font-size: 3rem;
    text-align: center;
    font-family: $primaryFront;
    font-weight: 500;
    letter-spacing: 0em;
    border-radius: 1rem;
    border: none;
    margin: 2rem auto;
    display: block;
    background-color: $primaryColor;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 5rem;
  }

  // .go-home:hover {
  //   background-color: $secondaryColor;
  // }

  .cart-product {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-items: center;
    grid-gap: 2rem;
  }

  .cart-buttom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin: 2rem;
    button {
      font-size: 3rem;
      background-color: rgb(53, 53, 53);
      padding: 1rem 3rem;
      border: none;
      margin: 0.5rem;
      color: $whiteColor;
      border-radius: 1rem;
      font-family: $secondaryFront;
      cursor: pointer;
      text-transform: uppercase;
      .icon {
        margin-bottom: -0.7rem;
      }
    }
    button:hover {
      background-color: $primaryColor;
    }
  }
}

@media (max-width: 1380px) {
  .cart-container {
    .cart-product {
      grid-template-columns: repeat(3, auto);
    }
  }
}

@media (max-width: 1064px) {
  .cart-container {
    .cart-product {
      grid-template-columns: repeat(2, auto);
    }
  }
}
@media (max-width: 772px) {
  .cart-container {
    .cart-buttom-container {
      button {
        font-size: 2.3rem;
      }
    }
  }
}
@media (max-width: 656px) {
  .cart-container {
    .cart-buttom-container {
      flex-direction: column;
      gap: 1rem;
    }
  }
}

@media (max-width: 580px) {
  .cart-container {
    .empty-heading {
      font-size: 3.2rem;
    }
    .go-home {
      font-size: 3rem;
    }
  }
}
@media (max-width: 480px) {
  .cart-container {
    .heading {
      font-size: 4rem;
    }
    .empty-heading {
      font-size: 2.8rem;
    }
    .cart-item-number {
      font-size: 1.5rem;
    }
    .go-home {
      font-size: 2.5rem;
    }
  }
}

@media (max-width: 460px) {
  .cart-container {
    .cart-product {
      grid-template-columns: repeat(1, auto);
    }
  }
}
@media (max-width: 335px) {
  .cart-container {
    .heading {
      font-size: 3.8rem;
    }
    .empty-heading {
      font-size: 2.5rem;
    }
    .cart-item-number {
      font-size: 1.3rem;
    }
    .go-home {
      font-size: 2.2rem;
    }
  }
}
@media (max-width: 300px) {
  .cart-container {
    .heading {
      font-size: 3.5rem;
    }
    .empty-heading {
      font-size: 2.4rem;
    }
    .cart-item-number {
      font-size: 1.2rem;
    }
    .go-home {
      font-size: 1.9rem;
    }
  }
}

@media (max-width: 338px) {
  .cart-container {
    .cart-buttom-container {
      button {
        font-size: 1.8rem;
      }
    }
  }
}
@media (max-width: 290px) {
  .cart-container {
    .cart-buttom-container {
      button {
        font-size: 1.6rem;
      }
    }
  }
}

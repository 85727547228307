$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.footer {
  background-color: $BlackColor;
  padding: 3rem;
  .footer-container {
    width: 75%;
    margin: 0 auto;
    margin-bottom: -2rem;
    .footer-contact {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid $ternaryColor;
      gap: 2rem;
      .address{
        padding-right: 1rem;
      }
      .box {
        width: 100%;
        text-align: center;
        border-right: 1px solid $ternaryColor;
        margin: 3rem 0;
        min-height: 15rem;
        h4 {
          font-family: $primaryFront;
          font-size: 36px;
          font-weight: 500;
          letter-spacing: 0em;
          text-transform: uppercase;
          color: $whiteColor;
          margin-bottom: 2rem;
        }
        p {
          font-family: $secondaryFront;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.3em;
          text-transform: uppercase;
          color: $whiteColor;
        }
        a {
          text-decoration: none;
          color: $whiteColor;
          font-family: $secondaryFront;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.3em;
          text-transform: uppercase;
        }
      }
      .email {
        border: none;
      }
    }
    .footer-Links {
      width: 70%;
      margin: 2rem auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .socal-link {
        padding: 1rem;
        .icon {
          font-size: 4rem;
          padding: 1rem;
          margin: 1rem;
          border: 1px solid $whiteColor;
          color: #bfaa7f;
          transition: transform 0.4s;

        }
        .icon:hover {
          background-color: #bfaa7f;
          color: $BlackColor;
          transform: scale(1.1);
        }
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        a {
          border-right: 1px solid #9e9e9e;
          li {
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.1em;
            text-align: center;
            list-style: none;
            padding: 0 1.5rem;
            color: #9e9e9e;
            text-transform: uppercase;
          }
          
        }
        a:last-child {
          border: none;
        }
      }
      .copy-right {
        padding: 2rem;
        text-align: center;
        a{
          color: #bfaa7f;

        }
        p {
          font-family: Poppins;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.3em;
          color: #bfaa7f;
          span {
            color: #484848;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

@media (max-width: 1330px) {
  .footer {
    .footer-container {
      .footer-contact {
        .box {
          h4 {
            font-size: 33px;
          }
          p {
            font-size: 13px;
          }
          a {
            font-size: 13px;
          }
        }
      }
      .footer-Links {
        .copy-right {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 1162px) {
  .footer {
    .footer-container {
      .footer-contact {
        .box {
          h4 {
            font-size: 30px;
          }
          p {
            font-size: 10px;
          }
          a {
            font-size: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 1110px) {
  .footer {
    .footer-container {
      .footer-Links {
        .copy-right {
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 856px) {
  .footer {
    .footer-container {
      width: 90%;
      .footer-contact {
        .box {
          h4 {
            font-size: 25px;
          }
          p {
            font-size: 8px;
          }
          a {
            font-size: 8px;
          }
        }
      }
      .footer-Links {
        .copy-right {
          p {
            font-size: 8px;
          }
        }
      }
    }
  }
}


@media (max-width: 746px) {
  .footer {
    .footer-container {
      width: 90%;
      .footer-contact {
        .box {
          h4 {
            font-size: 25px;
          }
          p {
            font-size: 8px;
          }
          a {
            font-size: 8px;
            // margin: 0 3rem;
          }
          // .email-a{
          //   margin-left: 2rem;
          // }
        }
      }
      .footer-Links {
        .copy-right {
          p {
            font-size: 8px;
          }
        }
      }
    }
  }
}


@media (max-width: 613px) {
  .footer {
    .footer-container {
      width: 100%;
      .footer-contact {
        .box {
          h4 {
            font-size: 18px;
          }
          p {
            font-size: 8px;
          }
          a {
            font-size: 8px;
          }
        }
      }
      .footer-Links {
        .copy-right {
          text-align: center;
          p {
            font-size: 8px;
            span {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .footer {
    .footer-container {
      .footer-contact {
        flex-direction: column;
        .box {
          display: flex;
          flex-direction: column;
          border: none;
          margin: 0;
          padding: 1rem;
          border-bottom: 1px solid #373737;
          justify-content: center;
        }
        .email {
          border: none;
          margin: 0;
        }
      }
      .footer-Links {
        .copy-right {
          text-align: center;
          p {
            font-size: 8px;
            span {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.video-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .video-box {
    position: relative;
    height: 100vh;
    // margin-top: 4px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #505050f8;
    .close {
      font-size: 5rem;
      position: absolute;
      top: 0;
      right: 0;
      // border-radius: 50%;
      // transform: translateX(50%);
      z-index: 9;
      // mix-blend-mode: difference;
      color: $whiteColor;
      // background-color: $whiteColor;
    }
    video {
      // height: 49.9rem;
      width: 100%;
      // height: 100%;
    }
  }
}

// @media (max-width: 900px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 45rem;
//       }
//     }
//   }
// }
// @media (max-width: 850px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 43rem;
//       }
//     }
//   }
// }
// @media (max-width: 880px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 41rem;
//       }
//     }
//   }
// }

// @media (max-width: 770px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 40rem;
//       }
//     }
//   }
// }
// @media (max-width: 740px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 34.7rem;
//       }
//     }
//   }
// }
// @media (max-width: 650px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 30rem;
//       }
//     }
//   }
// }
// @media (max-width: 550px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 25rem;
//       }
//     }
//   }
// }
// @media (max-width: 480px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 20rem;
//       }
//     }
//   }
// }
// @media (max-width: 390px) {
//   .video-container {
//     .video-box {
//       video {
//         // height: 15rem;
//       }
//     }
//   }
// }

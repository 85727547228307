$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.product-card {
  width: 30rem;
  height: 43rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
  transition: 0.3s;
  border: 2px solid #eee;

  img {
    width: 100%;
    max-height: 23rem;
  }
  .details {
    text-align: center;

    .product-heading {
      font-family: $primaryFront;
      font-size: 20px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0.005em;
      text-align: center;
      margin-bottom: 1rem;
    }
    .product-sub {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.005em;
      text-align: center;
      display: block;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.005em;
      text-align: center;
      color: $secondaryColor;
      margin-top: 1rem;
    }
  }
  .btn {
    margin: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5rem;
    .button {
      padding: 1rem;
      font-size: 6rem;
      text-align: center;
      margin: 0.5rem 0;
      border-radius: 2rem;
      cursor: pointer;
      // background-color: rgb(196, 192, 192);
      border: 2px solid #eee;
    }
    .add-cart {
      color: green;
    }
    .delet {
      color: red;
    }
    .add-cart:hover,
    .book-now:hover,.delet:hover {
      background-color: rgb(196, 192, 192);
    }
    .book-now {
      color: $primaryColor;
    }
  }
}
.product-card:hover {
  box-shadow: 6px 10px 45px 0px $ternaryColor;
}

@media (max-width: 778px) {
  .product-card {
    width: 26rem;
    height: 40rem;
    img {
      width: 80%;
      max-height: 20rem;
    }
  }
}
@media (max-width: 590px) {
  .product-card {
    width: 20rem;
    padding: 0.8rem;
    img {
      width: 80%;
      max-height: 60%;
    }
    .details {
      .product-heading {
        font-size: 15px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  .product-card:hover {
    .product-sub {
      font-size: 10px;
    }
  }
}
@media (max-width: 590px) {
  .product-card {
    width: 19rem;
  }
}
@media (max-width: 460px) {
  .product-card {
    width: 95%;
    height: 100%;
  }
}

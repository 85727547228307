$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$headingColor: #3d4759;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;


.booking-container{
  display: flex;
  justify-content: space-around;
  margin: 10rem 0;
}
.category-name{
  text-transform: capitalize;
}
.booking-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  width: 40%;
  .form {
    // margin: 10rem 0;
    border-radius: 10px;
    padding: 2rem;
    position: relative;
    // width: 60%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    h2 {
      color: $headingColor;
      font-size: 24px;
      font-weight: 600;
      margin: 1rem 0;
    }
    .group {
      // display: flex;
      display: block;
      justify-content: space-between;
      align-items: center;
      input {
        margin-top: 2rem;
        width: 100%;
        border: none;
        border-radius: 4px;
        font-size: 2rem;
        padding: 1rem;
        background-color: $whiteColor;
      }
    }
    input {
      margin-top: 2rem;
      width: 100%;
      border: none;
      border-radius: 4px;
      font-size: 2rem;
      padding: 1rem;
      background-color: $whiteColor;
    }
    textarea {
      margin-top: 2rem;
      width: 100%;
      border: none;
      border-radius: 4px;
      font-size: 2rem;
      padding: 1rem;
      background-color: $whiteColor;
    }
    button {
      margin-top: 0.5rem;
      padding: 2rem 0.2rem;
      background:$primaryColor;
      border: none;
      border-radius: 10px;
      color: $whiteColor;
      font-size: 18px;
      font-weight: 600;
      width: 195px;
      cursor: pointer;
    }
  }
}
.table{
  width: 30%;
  .imgBox{
   img{
    width: 100%;
    max-height: 30rem;
   }
  }
}


@media (max-width: 860px) {
  .booking-container{
    flex-direction: column;
    align-items: center;
  }
  .table{
    width: 80%;
    .imgBox{
     img{
      width: 100%;
     }
    }
  }
  .booking-form {
    top: 14%;
    margin-top: 10rem;
    width: 80%;
    .form {
      .group {
        flex-direction: column;
        gap: 1rem;
        input {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .booking-form {
    top: 10%;
    .form {
      width: 80%;
      margin: 7rem 0;
    }
  }
}
@media (max-width: 640px) {
  .booking-form {
    top: 10%;
    .form {
      width: 90%;
      margin: 5rem 0;
      .group { 
        input {
       font-size: 1.5rem;
          
        }
      }
      input {
       font-size: 1.5rem;
      }
      textarea {
        font-size: 1.5rem;

      }
      button {
        padding: 1.5rem 0.2rem;
        font-size: 15px;
        width: 150px;
      }
    }
  }
}

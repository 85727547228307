$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.more-container {
  margin-left: 2rem;
  color: $BlackColor;
  cursor: pointer;
  transition:transform 0.4s ;

  .more {
    font-family: $secondaryFront;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.3em;
    .arrow {
      margin-bottom: -5px;
    }
  }
  .dash {
    width: 13rem;
    margin-left: -2rem;
    border-bottom: 2px solid $BlackColor;
    margin-top: 1rem;
  }
}
.more-container:hover {
  transform: scale(1.09);
}
@media (max-width: 1080px) {
  .more-container {
    .more {
      font-size: 1.3rem;
    }
    .dash {
      width: 11rem;
    }
  }
}

@media (max-width: 770px) {
  .more-container {
    .more {
      font-size: 1.1rem;
    }
    .dash {
      width: 8rem;
    }
  }
}
@media (max-width: 577px) {
  .more-container {
    .more {
      font-size: 0.9rem;
    }
    .dash {
      width: 6rem;
    }
  }
}
@media (max-width: 310px) {
    .more-container {
        .-more {
          font-size: 0.7rem;
        }
        .dash {
          width: 4rem;
        }
      }
}

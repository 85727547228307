$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.contact-bannar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    height: 38rem;
    aspect-ratio: 4/1;
    width: 100%;
  }
  h1 {
    font-size: 48px;
    color: $whiteColor;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 1100px) {
  .contact-bannar {
    img {
      height: 35rem;
    }
    h1 {
      font-size: 42px;
    }
  }
}

@media (max-width: 720px) {
  .contact-bannar {
    img {
      height: 28rem;
    }
    h1 {
      font-size: 31px;
    }
  }
}

@media (max-width: 500px) {
  .contact-bannar {
    img {
      height: 20rem;
    }
    h1 {
      font-size: 25px;
    }
  }
}
@media (max-width: 420px) {
  .contact-bannar {
    img {
      height: 15rem;
    }
    h1 {
      font-size: 20px;
    }
  }
}

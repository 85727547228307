.product-page{
    overflow: hidden;
    padding-top: 10rem;
    // margin-top: -2rem;
}
@media (max-width: 1033px) {
    .product-page{
        padding-top: 10rem;
    }
}
@media (max-width: 877px) {
    .product-page{
        padding-top: 8rem;
    }
}
@media (max-width: 722px) {
    .product-page{
        padding-top: 6.4rem;
    }
}
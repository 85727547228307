$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.all-product-container {
  // padding: 2rem 10rem;
  margin: 10rem 0;
  width: 85%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .heading-container {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .heading {
      font-family: $primaryFront;
      font-size: 38px;
      font-weight: 500;
      line-height: 51px;
      letter-spacing: 0.005em;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .catagories {
      display: flex;
      justify-content: space-around;
      width: 100%;
      overflow-x: scroll;
      .flex {
        display: flex;
        justify-content: center;
        gap: 1rem;
      }
      li {
        list-style: none;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.005em;
        text-align: start;
        padding: 1rem;
        color: $secondaryColor;
        white-space: nowrap;
        padding: 1rem 1.5rem;
        border-radius: 3rem;
        cursor: pointer;
        text-transform: capitalize;
      }
      .active {
        color: $primaryColor;
      }
      li:hover {
        background-color: $primaryColor;
        color: $whiteColor;
      }
    }
  }
  .product-container {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-items: center;
    grid-gap: 2rem;
  }
  .learn--container {
    color: black;
    cursor: pointer;
    .learn-more {
      font-family: $secondaryFront;
      font-size: 15px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0.2em;
      text-align: left;

      .arrow {
        margin-bottom: -5px;
      }
    }
    .dash {
      width: 10rem;
      margin-left: -2rem;
      border-bottom: 2px solid $BlackColor;
      margin-top: 1rem;
    }
  }
  .learn--container:hover {
    bottom: 11%;
  }
  .page-btn-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0;
    button {
      // background-color: $primaryColor;
      // color: $whiteColor;
      .icon-button {
        font-size: 3rem;
      }
    }
  }
}
@media (max-width: 1380px) {
  .all-product-container {
    .product-container {
      grid-template-columns: repeat(3, auto);
    }
  }
}
@media (max-width: 1064px) {
  .all-product-container {
    .product-container {
      grid-template-columns: repeat(2, auto);
    }
    
  }
}

@media (max-width: 613px) {
  .all-product-container {
    .heading-container {
      margin: 0 auto;
      .heading {
        font-size: 30px;
        margin-bottom: 0;
      }
      .catagories {
        li {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
    
  }
}
@media (max-width: 460px) {
  .all-product-container {
    .heading-container {
      width: 98%;
      .heading {
        font-size: 25px;
      }
    }
    
  }
}

@media (max-width: 460px) {
  .all-product-container {
    .product-container {
      grid-template-columns: repeat(1, auto);
    }
  }
}

$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.gallery {
  width: 100%;
  .gallery-text {
    margin: 3rem auto;
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-family: $primaryFront;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .gallery-container {
    position: relative;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 1rem;
    img {
      width: 100%;
      height: 100%;
      transition: transform 0.4s;
    }
    img:hover {
      transform: scale(1.1);
    }
    a {
      .instagram {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2.5rem;
        background-color: $whiteColor;
        top: 32%;
        right: 45%;
        cursor: pointer;
        transition: transform 0.2s ease-in;
        img {
          width: 50%;
          height: 50%;
        }
        p {
          font-family: $primaryFront;
          font-size: 26px;
          font-weight: 500;
          letter-spacing: 0em;
          text-align: center;
          color: black;
        }
      }
      .instagram:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: 1130px) {
  .gallery {
    .gallery-container {
      a {
        .instagram {
          top: 27%;
          right: 42%;
        }
      }
    }
  }
}
@media (max-width: 1080px) {
  .gallery {
    .gallery-text {
      h1 {
        font-size: 40px;
      }
    }
    .gallery-container {
      a {
        .instagram {
          padding: 1.7rem;
          top: 23%;
          right: 42%;
        }
      }
    }
  }
}
@media (max-width: 870px) {
  .gallery {
    .gallery-container {
      a {
        .instagram {
          padding: 1.7rem;
          top: 18%;
          right: 41%;
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .gallery {
    .gallery-text {
      h1 {
        font-size: 35px;
      }
    }
    .gallery-container {
      grid-template-columns: repeat(2, auto);

      a {
        .instagram {
          padding: 1.7rem;
          top: 41%;
          right: 41%;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .gallery {
    .gallery-container {
      a {
        .instagram {
          padding: 1.7rem;
          top: 40%;
          right: 39%;
        }
      }
    }
  }
}
@media (max-width: 577px) {
  .gallery {
    .gallery-text {
      h1 {
        font-size: 28px;
      }
    }
    .gallery-container {
      a {
        .instagram {
          padding: 1.7rem;
          top: 37%;
          right: 37%;
        }
      }
    }
  }
}
@media (max-width: 460px) {
  .gallery {
    .gallery-text {
      width: 90%;
    }
    .gallery-container {
      a {
        .instagram {
          padding: 1.7rem;
          top: 34%;
          right: 34%;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .gallery {
    .gallery-container {
      a {
        .instagram {
          padding: 1.3rem;
          top: 32%;
          right: 32%;
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .gallery {
    .gallery-text {
      h1 {
        font-size: 24px;
      }
    }
    .gallery-container {
      a {
        .instagram {
          top: 31%;
          right: 31%;
        }
      }
    }
  }
}
@media (max-width: 310px) {
  .gallery {
    .gallery-text {
      h1 {
        font-size: 20px;
      }
    }
    .gallery-container {
      a {
        .instagram {
          top: 29%;
          right: 29%;
        }
      }
    }
  }
}

$primaryColor : #C89631;
$secondaryColor : #505050;
$ternaryColor : #787878;
$BlackColor : #000000;
$whiteColor : #ffff;
$primaryFront : 'Playfair Display', serif;
$secondaryFront : 'Poppins', sans-serif;
$ternaryFront : 'Roboto', sans-serif;

.nav-container {
  position: fixed;
  z-index: 9999;
  display: flex;
  width: 100%;
  background-color: $whiteColor;
  .logo {
    border: 1px solid $ternaryColor;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
      width: fit-content;
    img {
      margin: 1rem;
      width: 80%;
    }
  }
  .navbar-container {
    border: 1px solid $ternaryColor;
    width: 100%;
    .announcement {
      border-bottom: 1px solid $ternaryColor;
    }
    .nav-bar {
      .nav-menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .nav-link {
          list-style: none;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          width: 100%;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 8rem;
            border-right: 1px solid $ternaryColor;
            font-size: 1.5rem;
            text-decoration: none;
            transition: transform 1s;

            li {
              color: $secondaryColor;
            }
            .cart{
              font-size: 4rem;
              position: relative;
              .cart-item-number{
                font-size: 1.5rem;
                position: absolute;
                padding: 0.3rem 0.6rem;
                background-color: red;
                color: $whiteColor;
                top: -9px;
                right: -7px;
                border-radius: 10px;
              }
              .hidden{
                display: none;
                visibility: hidden;
              }
            }
          }
         
          a:hover {
            li {
              color: $primaryColor;
              transform: scale(1.2);
            }
          }
          .active {
            li{
              color: $primaryColor;
              transform: scale(1.2);
            }
          }
        }
      }
      .bg-yellow {
        font-size: 4rem;
        height: 8rem;
        padding: 2rem;
        background-color: $primaryColor;
        width: fit-content;
      }
      .mb-bar {
        display: none;
      }
    }
  }
}

@media (max-width: 1032px) {
  .nav-container {
    .navbar-container {
      .nav-bar {
        .nav-menu {
          .nav-link {
            a {
              font-size: 1rem;
            }
          }
        }
        .box {
          font-size: 3rem;
        }
      }
    }
  }
}
@media (max-width: 862px) {
  .nav-container {
    .logo {
      img {
        width: 55%;
      }
    }
    .navbar-container {
      .nav-bar {
        .nav-menu {
          .nav-link {
            a {
              font-size: 1rem;
              height: 6.4rem;
            }
          }
        }
        .box {
          height: 6.4rem;
        }
      }
    }
  }
}
@media (max-width: 722px) {
  .nav-container {
    .logo {
      img {
        margin: 0;
       width: 50%;
      }
    }
    .navbar-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .announcement {
        border-bottom: none;
        width: 90%;
      }
      .nav-bar {
        .nav-menu {
          position: fixed;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
          padding: 2rem;
          width: 60%;
          background-color: $whiteColor;
          top:6.5rem;
          right: 0;
          height: 90vh;
          z-index: 100;
          transform: translateX(180%);
          transition: transform 0.5s;
          .nav-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            width: 100%;
            a {
              width: 100%;
              font-size: 1.5rem;
              border: 1px solid $secondaryColor;
              text-align: center;
              text-decoration: none;
              height: fit-content;
              padding: 1.5rem;
              li {
                color: $BlackColor;
                list-style: none;
                width: 100%;
              }
              .cart{
                font-size: 3rem;
                .cart-item-number{
                right: 42%;
                }
              }
            }
            a:hover {
              li {
                color: $primaryColor;
              }
            }
          }
        }
        .mb-nav-menu{
          transform: translateX(0);
          transition: transform 0.5s;
        }
        .mb-bar {
          display: block;
        }
      }
    }
  }
}



@media (max-width: 480px) {
  .nav-container {
    .navbar-container {
      .nav-bar {
        .nav-menu {
          .nav-link {
            a {
              .cart{
                font-size: 3rem;
                .cart-item-number{
                right: 40%;
                }
              }
            }
            
          }
        }
      }
    }
  }
}



@media (max-width: 420px) {
  .nav-container {
    .navbar-container {
      .nav-bar {
        .nav-menu {
          .nav-link {
            a {
              .cart{
                font-size: 3rem;
                .cart-item-number{
                right: 37%;
                }
              }
            }
            
          }
        }
      }
    }
  }
}



@media (max-width: 350px) {
  .nav-container {
    .navbar-container {
      .nav-bar {
        .nav-menu {
          .nav-link {
            a {
              .cart{
                font-size: 3rem;
                .cart-item-number{
                right: 35%;
                }
              }
            }
            
          }
        }
      }
    }
  }
}




@media (max-width: 310px) {
  .nav-container {
    .navbar-container {
      .nav-bar {
        .nav-menu {
          .nav-link {
            a {
              .cart{
                font-size: 3rem;
                .cart-item-number{
                right: 33%;
                }
              }
            }
            
          }
        }
      }
    }
  }
}



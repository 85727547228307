$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.collection-container {
  width: 85%;
  margin: 2rem auto;
  margin-top: 7rem;
  .collection-box {
    width: 75%;
    margin: 2rem auto;
    text-align: center;
    h2 {
      font-family: $primaryFront;
      font-size: 3.5rem;
      font-weight: 500;
      line-height: 59px;
      letter-spacing: 0em;
      text-align: center;
    }
    p {
      font-family: $secondaryFront;
      font-size: 18px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .collection-box1 {
    padding: 1rem 2rem;
  }
}

@media (max-width: 1052px) {
  .collection-container {
    margin-top: 6rem;

    .collection-box {
      h2 {
        font-size: 3.2rem;
        line-height: 48px;
      }
      p {
        font-size: 13px;
        line-height: 28px;
      }
    }
  }
}
@media (max-width: 844px) {
  .collection-container {
    margin-top: 5rem;

    .collection-box {
      width: 85%;
    }
  }
}
@media (max-width: 844px) {
  .collection-container {
    .collection-box {
      h2 {
        font-size: 2.8rem;
        line-height: 39px;
      }
      p {
        font-size: 11px;
        line-height: 24px;
      }
    }
  }
}
@media (max-width: 470px) {
  .collection-container {
    margin-top: 4rem;

    .collection-box {
      h2 {
        font-size: 2.5rem;
        line-height: 32px;
      }
      p {
        font-size: 11px;
        line-height: 24px;
      }
    }
    .collection-box {
      padding: 1rem;
    }
  }
}

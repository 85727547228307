$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.banner {
  // height: 50rem;
  width: 100%;
  // background-image: url("../../asset/New\ Sunity\ Jweller’s_Image/Group 1000001250.jpg");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background-color: rgba(127, 127, 127, 0.266);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s;
  position: relative;
  .banner-img {
    height: 50rem;
    width: 100%;
  }
  .banner-container {
    position: absolute;
    color: $whiteColor;
    text-align: center;

    p {
      font-family: $secondaryFront;
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    h1 {
      font-family: $primaryFront;
      font-size: 62px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: center;
      text-transform: uppercase;
    }
    img {
      margin-top: 5rem;
      width: 9rem;
      cursor: pointer;
      transition: transform 0.5s;
    }
    img:hover {
      transform: scale(1.2);
    }
  }
}
.banner:hover {
  transform: scale(1.1);
}

@media (max-width: 900px) {
  .banner {
    .banner-img {
      height: 45rem;
    }
    .banner-container {
      p {
        font-size: 15px;
      }
      h1 {
        font-size: 55px;
      }
      img {
        margin-top: 3.5rem;
        width: 7rem;
      }
    }
  }
}
@media (max-width: 900px) {
  .banner {
    .banner-img {
      height: 43rem;
    }
  }
}
@media (max-width: 850px) {
  .banner {
    .banner-img {
      height: 43rem;
    }
  }
}
@media (max-width: 800px) {
  .banner {
    .banner-img {
      height: 41rem;
    }
  }
}
@media (max-width: 770px) {
  .banner {
    .banner-img {
      height: 40rem;
    }
    .banner-container {
      p {
        font-size: 12px;
      }
      h1 {
        font-size: 45px;
      }
      img {
        margin-top: 3rem;
        width: 5rem;
      }
    }
  }
}
@media (max-width: 740px) {
  .banner {
    .banner-img {
      height: 35rem;
    }
  }
}
@media (max-width: 650px) {
  .banner {
    .banner-img {
      height: 30rem;
    }
  }
}
@media (max-width: 600px) {
  .banner {
    .banner-img {
      height: 30rem;
    }
    .banner-container {
      p {
        font-size: 10px;
      }
      h1 {
        font-size: 30px;
      }
      img {
        margin-top: 2rem;
        width: 4rem;
      }
    }
  }
}
@media (max-width: 550px) {
  .banner {
    .banner-img {
      height: 25rem;
    }
  }
}
@media (max-width: 480px) {
  .banner {
    .banner-img {
      height: 20rem;
    }
    .banner-container {
      p {
        font-size: 10px;
      }
      h1 {
        font-size: 26px;
      }
      img {
        margin-top: 2rem;
        width: 4rem;
      }
    }
  }
}
@media (max-width: 390px) {
  .banner {
    .banner-img {
      height: 15rem;
    }
  }
}

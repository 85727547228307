@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,500&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;1,100&family=Roboto:ital,wght@0,500;0,700;0,900;1,400;1,500&display=swap');

$primaryColor : #C89631;
$secondaryColor : #505050;
$ternaryColor : #787878;
$BlackColor : #000000;
$whiteColor : #ffff;
$primaryFront : 'Playfair Display', serif;
$secondaryFront : 'Poppins', sans-serif;
$ternaryFront : 'Roboto', sans-serif;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $ternaryFront;
}
html {
  font-size: 62.5%;
}
a{
  text-decoration: none;
  
}
.main-home-page{
  overflow: hidden;
  padding-top: 10rem;
}

@media (max-width: 1033px) {
  .main-home-page{
      padding-top: 10rem;
  }
}
@media (max-width: 877px) {
  .main-home-page{
      padding-top: 8rem;
  }
}
@media (max-width: 722px) {
  .main-home-page{
      padding-top: 6.4rem;
  }
}
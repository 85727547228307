$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.Sale-Container {
  width: 70%;
  margin: 4rem auto;
  margin-top: 7rem;
  text-align: left;
  .Space-text {
    width: 50%;
    .the-text {
      width: 100%;
      border-right: 2px solid $ternaryColor;
      p {
        font-family: $primaryFront;
        font-size: 30px;
        font-weight: 500;
        text-align: left;
        text-transform: uppercase;
        padding: 1rem 0;
      }
    }
    .sale-text {
      width: 100%;
      h3 {
        font-family: $primaryFront;
        font-size: 48px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
      }
    }
  }
  .image-container {
    display: flex;
    justify-content: space-between;
    .left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 47%;
      padding: 0 1rem;
      .image-box {
        padding: 1rem;
        width: 85%;
        img {
          // height: 56rem;
          width: 100%;
          transition: transform 0.4s;
        }
        img:hover {
          transform: scale(1.04);
        }
        p {
          margin-top: 1rem;
          font-family: $secondaryFront;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0em;
          text-align: left;
          color: $secondaryColor;
        }
      }
      .learn--container {
        margin-top: 10rem;
      }
    }
    .right-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 48%;
      .image-box1 {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .image-inner-box {
          width: 59%;
          padding: 1rem;
          img {
            width: 100%;
            transition: transform 0.4s;
          }
          img:hover {
            transform: scale(1.04);
          }
          p {
            font-family: "Poppins";
            margin-top: 1rem;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0em;
            text-align: left;
            color: $secondaryColor;
          }
        }
        .rotat-pra {
          width: 10%;
          padding: 1rem 0;
          p {
            rotate: 90deg;
            font-family: $primaryFront;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 0.1em;
            text-align: left;
            white-space: nowrap;
            text-transform: uppercase;
          }
        }
      }
      .image-box2 {
        width: 100%;
        padding: 1rem;
        img {
          width: 100%;
          transition: transform 0.4s;
        }
        img:hover {
          transform: scale(1.04);
        }
        h4 {
          font-family: $primaryFront;
          font-size: 36px;
          font-weight: 500;
          letter-spacing: 0.1em;
          text-align: left;
          margin: 1rem 0;
        }
        p {
          font-family: $secondaryFront;
          margin-top: 1rem;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0em;
          text-align: left;
          color: $secondaryColor;
        }
      }
    }
  }
}

@media (max-width: 1141px) {
  .Sale-Container {
    width: 80%;
    margin-top: 6rem;

    .Space-text {
      .the-text {
        p {
          font-size: 25px;
        }
      }
      .sale-text {
        h3 {
          font-size: 42px;
        }
      }
    }
    .image-container {
      .right-container {
        .image-box1 {
          .rotat-pra {
            p {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .Sale-Container {
    margin-top: 5rem;
    width: 85%;
    .image-container {
      .left-container {
        .image-box {
          p {
            margin-top: 0.8rem;
            font-size: 10px;
          }
        }
      }
      .right-container {
        .image-box1 {
          .image-inner-box {
            p {
              margin-top: 0.8rem;
              font-size: 10px;
            }
          }
          .rotat-pra {
            p {
              font-size: 22px;
            }
          }
        }
        .image-box2 {
          h4 {
            font-size: 30px;
            margin: 0.8rem 0;
          }
          p {
            margin-top: 0.8rem;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 732px) {
  .Sale-Container {
    .Space-text {
      .the-text {
        p {
          font-size: 20px;
        }
      }
      .sale-text {
        h3 {
          font-size: 37px;
        }
      }
    }
    .image-container {
      .left-container {
        .learn--container {
          margin-top: 3rem;
        }
      }
      .right-container {
        .image-box1 {
          .rotat-pra {
            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 732px) {
  .Sale-Container {
    .Space-text {
      .the-text {
        p {
          font-size: 15px;
          padding: 0.5rem 0;
        }
      }
      .sale-text {
        h3 {
          font-size: 27px;
        }
      }
    }
    .image-container {
      .left-container {
        .learn--container {
          margin-top: 3rem;
        }
      }
      .right-container {
        .image-box1 {
          .rotat-pra {
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 551px) {
  .Sale-Container {
    margin-top: 4rem;

    .image-container {
      .left-container {
        .image-box {
          p {
            margin-top: 0.4rem;
            font-size: 7px;
          }
        }
      }
      .right-container {
        .image-box1 {
          .image-inner-box {
            p {
              margin-top: 0.4rem;
              font-size: 7px;
            }
          }
          .rotat-pra {
            p {
              font-size: 12px;
            }
          }
        }
        .image-box2 {
          h4 {
            font-size: 23px;
            margin: 0.4rem 0;
          }
          p {
            margin-top: 0.4rem;
            font-size: 7px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .Sale-Container {
    .Space-text {
      width: 80%;
      margin: 0 auto;
      .the-text {
        border: none;
      }
    }
    .image-container {
      flex-direction: column;
      .left-container {
        width: 100%;
        align-items: center;
        .image-box {
          p {
            margin-top: 0.4rem;
            font-size: 7px;
          }
        }
        .learn--container {
          margin-top: 1rem;
          margin-bottom: 2rem;
        }
      }
      .right-container {
        width: 100%;
        .image-box1 {
          justify-content: center;
          .image-inner-box {
            p {
              margin-top: 0.4rem;
              font-size: 7px;
            }
          }
          .rotat-pra {
            width: 5%;
            p {
              font-size: 12px;
            }
          }
        }
        .image-box2 {
          h4 {
            font-size: 23px;
            margin: 0.4rem 0;
          }
          p {
            margin-top: 0.4rem;
            font-size: 7px;
          }
        }
      }
    }
  }
}

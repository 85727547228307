.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 10rem 0;
  .about {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 75%;
    gap: 4rem;

    .about-text {
      .about-us-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        .about-us {
          font-family: "Poppins";
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.3em;
          text-align: left;
        }
        .dash {
          width: 6rem;
          border-bottom: 1px solid #000000;
        }
      }
      h1 {
        font-family: "Playfair", "Display";
        font-size: 48px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        margin: 1.2rem 0;
      }
      p {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
      }
      .learn--container {
        margin-top: 5rem;
      }
    }
    .about-image-container {
      position: relative;
      padding: 3rem;
      .images {
        .tow-img {
          padding-bottom: 2rem;
          display: flex;
          align-items: flex-end;
          gap: 2rem;
          .one {
            height: 37rem;
            transition: transform 0.4s;
          }
          .tow {
            height: 20rem;
            transition: transform 0.4s;
          }
          .one:hover , .tow:hover {
            transform: scale(1.1);
          }
        }
        .map {
          display: block;
          margin: 0 auto;
          height: 25rem;
          width: 98%;
        }
      }
      .about-rotat {
        position: absolute;
        top: 45%;
        right: -28%;  
        rotate: 90deg;
        h1 {
          font-family: "Playfair", "Display";
          font-size: 36px;
          font-weight: 500;
          letter-spacing: 0.1em;
          text-align: left;
          text-transform: uppercase;
        }
      }
    }
  }
}


@media (max-width : 1460px) {
  .about-container {
  margin: 8rem 0;

    .about {  
      .about-text {
        .about-us-container {
          gap: 1rem;
          .about-us {
            font-size: 15px;
          }
          .dash {
            width: 6rem;
          }
        }
        h1 {
          font-size: 45px;
        }
        p {
          font-size: 18px;
        }
      }
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 32rem;
            }
            .tow {
              height: 15rem;
            }
          }
          .map {
            height: 21rem;
          }
        }
        .about-rotat {
          top: 45%;
          right: -31%;
          h1 {
            font-size: 32px;

          }
        }
      }
    }
  }
}
@media (max-width : 1390px) {
  .about-container {
    .about {
      .about-text {
        .about-us-container {
          gap: 1rem;
          .about-us {
            font-size: 13px;
          }
          .dash {
            width: 5rem;
          }
        }
        h1 {
          font-size: 43px;
        }
        p {
          font-size: 15px;
        }
        .learn--container {
          margin-top: 3rem;
        }
      }
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 28rem;
            }
            .tow {
              height: 15rem;
            }
          }
          .map {
            height: 20rem;
          }
        }
      }
    }
  }
}
@media (max-width : 1270px) {
  .about-container {
    .about {
      .about-text {
        h1 {
          font-size: 38px;
        }
        p {
          font-size: 13px;
        }
        .learn--container {
          margin-top: 2.5rem;
        }
      }
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 25rem;
            }
            .tow {
              height: 13rem;
            }
          }
          .map {
            height: 20rem;
          }
        }
        .about-rotat {
          top: 45%;
          right: -28%;
          h1 {
            font-size: 27px;

          }
        }
      }
    }
  }
}
@media (max-width : 924px) {
  .about-container {
  margin: 7rem 0;

    .about {
      .about-text {
        h1 {
          font-size: 28px;
        }
        p {
          font-size: 13px;
        }
      }
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 21rem;
            }
            .tow {
              height: 12rem;
            }
          }
          .map {
            height: 18rem;
          }
        }
        .about-rotat {
          top: 45%;
          right: -25%;
          h1 {
            font-size: 22px;

          }
        }
      }
    }
  }
}
@media (max-width : 810px) {
  .about-container {
    .about {
      .about-text {
        h1 {
          font-size: 23px;
        }
        p {
          font-size: 11px;
        }
        .learn--container {
          margin-top: 2.1rem;
        }
      }
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 19rem;
            }
            .tow {
              height: 10rem;
            }
          }
          .map {
            height: 17rem;
          }
        }
        .about-rotat {
          top: 45%;
          right: -20%;
          h1 {
            font-size: 18px;

          }
        }
      }
    }
  }
}

@media (max-width : 675px) {
  .about-container {
  margin: 5rem 0;

    .about {

      .about-text {
        .about-us-container {
          gap: 1rem;
          .about-us {
            font-size: 8px;
          }
          .dash {
            width: 3rem;
          }
        }
        h1 {
          font-size: 17px;
        }
        p {
          font-size: 8px;
        }
        .learn--container {
          margin-top: 2rem;
        }
      }
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 15rem;
            }
            .tow {
              height: 9rem;
            }
          }
          .map {
            height: 15rem;
          }
        }
        .about-rotat {
          top: 45%;
          right: -20%;
          h1 {
            font-size: 15px;

          }
        }
      }
    }
  }
}

@media (max-width : 566px) {
  .about-container {
    padding: 3rem;
    .about {
      flex-direction: column;
      gap: 1rem;
    }
  }
}
@media (max-width : 347px) {
  .about-container {
    .about {
      .about-image-container {
        .images {
          .tow-img {
            .one {
              height: 12rem;
            }
            .tow {
              height: 6rem;
            }
          }
          .map {
            height: 12rem;
          }
        }
        .about-rotat {
          top: 40%;
          right: -13%;
          h1 {
            font-size: 10px;

          }
        }
      }
    }
  }
}
@media (max-width : 1040px) {
  .about-container {
    .about { 
      max-width: 85%;
    }
  }
}
$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$headingColor: #3d4759;
$whiteColor: #ffff;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.contact-page {
  .contact {
    // min-height: 50rem;
    width: 80%;
    margin: 10rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 49%;

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: $primaryColor;
      }
      h1 {
        font-size: 36px;
        font-weight: 700;
        margin: 1rem 0;
        color: $headingColor;
      }
      p {
        font-size: 24px;
        margin: 2rem 0;
        font-family: $ternaryFront;
      }

      .box {
        display: flex;
        align-items: center;
        font-size: 5rem;
        gap: 1rem;
        .icon {
          padding: 0.5rem;
          color: $primaryColor;
        }
        .box-content {
          p {
            margin: 0.5rem;
            font-size: 16px;
            font-family: $secondaryFront;

          }
          p:last-child {
            margin: 1rem;
            font-size: 1.6rem;
            font-weight: 700;
            color: $headingColor;
            line-height: 3rem;
            font-family: $ternaryFront;
            a{
              color: $headingColor;
            }
          }
          .phone-number {
            margin: 1rem;
            font-size: 1.5rem;
            font-weight: 700;
            color: $headingColor;
            display: block;
            font-family: $ternaryFront;

          }
        }
      }
    }
    .right {
      width: 49%;
      background-color: #eee;
      padding: 2rem;
      border: 10%;
      h2 {
        color: $headingColor;
        font-size: 24px;
        font-weight: 600;
        margin: 1rem 0;
      }
      .form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 3rem;
        .group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          input {
            width: 46%;
            border: none;
            border-radius: 4px;
            font-size: 2rem;
            padding: 1rem;
            background-color: $whiteColor;
          }
        }
        input {
          width: 100%;
          border: none;
          border-radius: 4px;
          font-size: 2rem;
          padding: 1rem;
          background-color: $whiteColor;
        }
        textarea {
          width: 100%;
          border: none;
          border-radius: 4px;
          font-size: 2rem;
          padding: 1rem;
          background-color: $whiteColor;
        }
        button {
          padding: 2rem 0.2rem;
          background: $primaryColor;
          border: none;
          border-radius: 10px;
          color: $whiteColor;
          font-size: 18px;
          font-weight: 600;
          width: 195px;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .contact-page {
    .contact {
      margin: 8rem auto;
      .left {
        width: 49%;

        h3 {
          font-size: 16px;
        }
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 22px;
          margin: 2rem 0;
        }
        .box {
          .box-content {
            p {
              margin: 0.5rem;
              font-size: 16px;
            }
            p:last-child {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .contact-page {
    .contact {

      .left {
        width: 49%;
        .box {
          font-size: 4rem;
          .box-content {
            p {
              margin: 0.5rem;
              font-size: 16px;
            }
            p:last-child {
              font-size: 1.5rem;
            }
          }
        }
      }
      .right {
        .form {
          .group {
            flex-direction: column;
            gap: 2rem;
            input {
              width: 100%;
              font-size: 2.2rem;
            }
          }
          input {
            font-size: 2.2rem;
          }
          textarea {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .contact-page {
    .contact {

      flex-direction: column;
      gap: 4rem;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .contact-page {
    .contact {
      margin: 6rem auto;
      .left {
        h3 {
          font-size: 13px;
        }
        h1 {
          font-size: 35px;
        }
        p {
          font-size: 18px;
        }
        .box {
          .box-content {
            p {
              font-size: 13px;
            }
            p:last-child {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .contact-page {
    .contact {
      width: 90%;
      .left {
        h3 {
          font-size: 13px;
        }
        h1 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
        .box {
          .box-content {
            p {
              font-size: 13px;
            }
            p:last-child {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

$primaryColor: #c89631;
$secondaryColor: #505050;
$ternaryColor: #787878;
$BlackColor: #000000;
$primaryFront: "Playfair Display", serif;
$secondaryFront: "Poppins", sans-serif;
$ternaryFront: "Roboto", sans-serif;

.announce-container {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  height: 4vh;
  .item-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem 2rem;
    font-size: 1.5rem;

    .item {
      color: $BlackColor;
      padding: 0 1rem;

      border-right: 1px solid $primaryColor;
      span {
        color: $primaryColor;
        font-weight: 700;
      }
    }
  }

  .socal {
    a{
    color: $ternaryColor;
    }
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: $ternaryColor;
    padding-right: 4rem;
    .face-book, .instagram{
      transition: transform 0.3s;
    }
    .face-book:hover {
      color: blue;
      transform: scale(1.08);
    }
    .instagram:hover {
      color: rgb(255, 0, 55);
      transform: scale(1.08);
    }
  }
}
@media (max-width: 600px) {
  .announce-container {
    .item-container {
      flex-direction: column;
      gap: 0.8rem;
      padding: 0 2rem;
      width: 100%;
      .item {
        border-right: none;
        border-bottom: 1px solid rgb(255, 225, 0);
        p {
          margin-bottom: 2px;
        }
      }
    }
  }
}
@media (max-width: 990px) {
  .item-container {
    .item {
      font-size: 1rem;
    }
  }
  .socal {
    font-size: 1rem;
  }
}
@media (max-width: 862px) {
  .item-container {
    .item {
      font-size: 1rem;
    }
  }
}
@media (max-width: 722px) {
  .announce-container {
    padding: 0;
    .item-container {
      .item {
        font-size: 1rem;
      }
    }
    .socal {
      display: none;
    }
  }
}
@media (max-width: 692px) {
  .announce-container {
    padding: 0;
    height: fit-content;
    .item-container {
      .item {
        font-size: 0.75rem;
      }
    }
    .socal {
      display: none;
    }
  }
}
@media (max-width: 590px) {
  .announce-container {
    .item-container {
      .item {
        font-size: 1.1rem;
      }
    }
  }
}
@media (max-width: 395px) {
  .announce-container {
    .item-container {
      .item {
        font-size: 1rem;
      }
    }
  }
}
@media (max-width: 379px) {
  .announce-container {
    .item-container {
      .item {
        font-size: 0.9rem;
      }
    }
  }
}
@media (max-width: 362px) {
  .announce-container {
    .item-container {
      .item {
        font-size: 0.8rem;
      }
    }
  }
}
@media (max-width: 346px) {
  .announce-container {
    .item-container {
      .item {
        font-size: 0.7rem;
      }
    }
  }
}
@media (max-width: 329px) {
  .announce-container {
    padding: 0;
    height: fit-content;
    .item-container {
      .item {
        font-size: 0.6rem;
      }
    }
    .socal {
      display: none;
    }
  }
}
@media (max-width: 313px) {
  .announce-container {
    .item-container {
      .item {
        font-size: 0.5rem;
      }
    }
  }
}
@media (max-width: 300px) {
  .announce-container {
    padding: 0;
    height: fit-content;
    .item-container {
      .item {
        font-size: 0.4rem;
      }
    }
    .socal {
      display: none;
    }
  }
}

$primaryColor : #C89631;
$secondaryColor : #505050;
$ternaryColor : #787878;
$BlackColor : #000000;
$whiteColor : #ffff;
$primaryFront : 'Playfair Display', serif;
$secondaryFront : 'Poppins', sans-serif;
$ternaryFront : 'Roboto', sans-serif;


.carousel {
  display: flex;
  width: 100%;
  .main {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    .indicators {
      position: absolute;
      bottom: 40%;
      right: 08%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .indicator {
        color: $whiteColor;
        background: transparent;
        padding: 0.2rem 0.8rem;
        font-family: $secondaryFront;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
      }
      .active {
        border: 1px solid $primaryColor;
      }
    }
  }
}

@media (max-width: 1206px) {
  .carousel {
    .main {
      .indicators {
        bottom: 30%;
        right: 08%;
      }
    }
  }
}
@media (max-width: 1033px) {
  .carousel {
    .main {
      .indicators {
        bottom: 30%;
        right: 08%;
        .indicator{
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 877px) {
  .carousel {
    // padding-top:8rem;
    .main {
      .indicators {
        bottom: 25%;
        right: 04%;
        .indicator{
          font-size: 11px;
        }
      }
    }
  }
}
@media (max-width: 722px) {
  .carousel {
    // padding-top: 6.4rem;
    .main {
      .indicators {
        bottom: 25%;
        right: 04%;
        gap: 1rem;
        .indicator{
          font-size: 8px;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .carousel {

    .main {
      .indicators {
        bottom: 25%;
        right: 04%;
        gap: 0.5rem;
        .indicator{
          font-size: 8px;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .carousel {

    .main {
      .indicators {
        bottom: 18%;
        right: 04%;
        gap: 0.2rem;
        .indicator{
          font-size: 6px;
        }
      }
    }
  }
}
@media (max-width: 410px) {
  .carousel {

    .main {
      .indicators {
        bottom: 13%;
        right: 04%;
        gap: 0.2rem;
        .indicator{
          font-size: 6px;
        }
      }
    }
  }
}
@media (max-width:390px) {
  .carousel {

    .main {
      .indicators {
        bottom: 10%;
        right: 04%;
        gap: 0rem;
        .indicator{
          font-size: 6px;
        }
      }
    }
  }
}
@media (max-width:340px) {
  .carousel {

    .main {
      .indicators {
        bottom: 9%;
        right: 04%;
        gap: 0rem;
        .indicator{
          font-size: 6px;
          padding:0 3px;
        }
      }
    }
  }
}
